@use 'sass:color';

@use '@material/theme' with (
  $primary: #5822e5,
  $secondary: #e8dfff,
  $background: #ffffff
);

@use '@material/banner';
@use '@material/dialog';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/drawer';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/icon-button/styles' as icon-button-styles;
@use '@material/image-list';
@use '@material/image-list/mdc-image-list';
@use '@material/linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/radio';
@use '@material/radio/styles' as radio-styles;
@use '@material/slider/styles' as slider-styles;
@use '@material/snackbar/mdc-snackbar';
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;
@include dialog.core-styles;

$form-field-width: 430px;
$mobile-form-field-width: 100%;
$mobile-width-max: 599px;
$tablet-width-min: 600px;

$success: green;

body {
  height: 100%;
  margin: 0;
}

h1 {
  @include typography.typography(headline4);
}

h2 {
  @include typography.typography(headline5);
}

h3 {
  @include typography.typography(headline6);
}

a,
div,
label,
li,
p,
span,
td,
th {
  @include typography.typography(body1);
  @media (min-width: $tablet-width-min) {
    font-size: 20px;
  }
}

#body-content {
  padding: 2em 0 0 0;
  max-width: 855px;
  margin: auto;
  @media (max-width: $tablet-width-min) {
    padding: 2em;
  }

  .page-spacer {
    @each $size in 1, 2, 3, 4, 5 {
      &.x#{$size} {
        height: #{$size}rem;
      }
    }
  }
}

#login,
#contact {
  max-width: 500px;
  margin: 0 auto;
}

#contact {
  .mdc-list-item__content,
  .mdc-list-item__primary-text {
    white-space: unset;
  }
}

.display-none {
  display: none;
}

.warning {
  background-color: pink;
}

// this accomodates for -2em margins below
@media (max-width: $tablet-width-min) {
  .trix-content {
    margin-left: 2em;
    margin-right: 2em;
  }
}

form {
  display: flex;
  flex-direction: column;
  max-width: 660px;

  &#page-form {
    max-width: unset;
    @media (max-width: $tablet-width-min) {
      margin-left: -2em;
      margin-right: -2em;
    }
  }

  label {
    margin-top: 0.5em;

    .field_with_errors {
      width: 100%;
    }
  }
}

nav {
  ol,
  li {
    list-style: none;
  }

  ol {
    padding: 1rem 0;
  }

  li {
    float: left;
  }

  li::after {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    content: ' / ';
  }

  li:last-child::after {
    display: none;
  }
}

.bold-class {
  font-weight: bolder;
}

.mdc-top-app-bar__title,
.fa-bars {
  color: theme.$background;
}

.favorited {
  color: red;
  --fa-animation-iteration-count: 2;
  --fa-animation-duration: 0.2s;
  --fa-beat-fade-scale: 2;
}

.favorites {
  outline: 1px solid rgba(0, 0, 0, 0.125);
  outline-offset: 25px;
}

#favorite-btn {
  margin: 0 50%;
  padding-top: 10px;

  #favorite-toggle {
    max-width: 50px;
    margin: 0 -25px;
  }
}

#favorite-btn-uncentered {
  #favorite-btn {
    margin: unset;
    padding-top: unset;

    #favorite-toggle {
      max-width: unset;
      margin: unset;
    }
  }
}

#activities-home-image-list {
  @include image-list.aspect(1.77);
  @include image-list.standard-columns(2);
  @media (max-width: $tablet-width-min) {
    @include image-list.standard-columns(1);
  }

  .mdc-image-list__supporting {
    height: 25%;
    background-color: theme.$secondary;
    width: 100%;
  }
}

.mdc-image-list__image-aspect-container,
.mdc-image-list__label {
  width: 100%;
  position: relative;
}

.mdc-form-field.radio-with-text {
  width: 100%;
}

.episode-title {
  text-align: left;
  color: theme.$background;
  font-size: 15px;
  position: absolute;
  margin-left: 5px;
}

.episode-progress {
  color: theme.$primary;
  font-size: 26px;
  text-align: center;
}

.episode-icon {
  position: absolute;
  text-align: center;
  width: 100%;
  padding-top: 20%;
  font-size: 60px;
}

.episode-icon.fa-check {
  color: $success;
}

.episode-icon.fa-ellipsis,
.episode-icon.fa-lock {
  color: theme.$primary;
}

.mdc-card {
  i {
    display: none;
  }

  &.selected {
    &.correct {
      @include card.fill-color(lightGreen);
      i {
        color: $success;
        display: initial;
      }
    }

    &.incorrect {
      @include card.fill-color(pink);
      i {
        color: red;
        display: initial;
      }
    }
  }
}

.mdc-list-item {
  .completed {
    color: $success;
  }
}

.multiple-choice-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  // magically make columns equal width
  & > * {
    flex: 1;
  }
  @media (max-width: $tablet-width-min) {
    display: block;
  }
}

.multiple-choice-question-card {
  margin: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  border-width: 4px;
  border-color: #e6e6e6;
  @media (min-width: $tablet-width-min) {
    max-width: 25%;
    min-width: 20%;
  }
}

.mdc-card__media-content {
  position: unset;
  top: unset;
  padding-bottom: 8px;
}

.feedback {
  text-align: center;
  margin: auto;
}

#feedback {
  padding: 10px;
  border-radius: 10px;
}

#feedback.incorrect {
  background: pink;
}

#feedback.correct {
  background: lightGreen;
}

.video-wrapper {
  padding: 50% 0 0 0;
  position: relative;

  @media (max-width: $tablet-width-min) {
    margin-top: 2.5%;
    padding: 25% 0;
  }

  .vimeo-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.mdc-banner__fixed {
  min-height: 60px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 32px;

  @media (max-width: $tablet-width-min) {
    padding-top: 28px;
  }
}

.grabbable {
  cursor: grabbing;
}

#topnav {
  @media (max-width: $tablet-width-min) {
    display: none;
  }
}

#mobilenav {
  @media (min-width: $mobile-width-max) {
    display: none;
  }
}

@media (max-width: $tablet-width-min) {
  .mobilebutton {
    display: flex;
    padding: 20px 0px;
    margin: 20px 10px 20px 10px;
    border: solid 1px;
  }
}

.accordion {
  overflow: hidden;

  .tab {
    overflow: hidden;

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input:checked {
      ~ .tab-label {
        .accordion-button {
          transform: rotate(-180deg);
          transition: all 0.5s;
        }
      }

      ~ .tab-content {
        max-height: 100em;
        transition: all 2s;
      }
    }

    &-label {
      display: flex;
      justify-content: space-between;

      .accordion-button {
        line-height: 2em;
        transform-origin: 0.3em 1em;
        transition: all 0.5s;
      }
    }

    &-content {
      max-height: 0;
      padding: 0 1em;
      transition: all 0.5s;
    }
  }
  .tab-content {
    padding: 0;
  }
}

.tab-label {
  background-color: theme.$primary;
  padding: 0 20px;
  margin: 10px 0;

  h2,
  .accordion-button {
    color: theme.$background;
    padding-top: 6px;

    @media (max-width: $tablet-width-min) {
      padding-top: unset;
    }
  }
}

.list-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid theme.$secondary;

  .mdc-list-item {
    flex-grow: 1;
  }

  .start-spacer {
    margin-right: 48px;
  }

  .fa-check,
  .fa-lock {
    padding-top: 8px;
  }
  .mdc-list-item__content {
    white-space: unset;
  }
}

.logo {
  padding-top: 10px;
}

.large-logo {
  max-height: 250px;
  @media (max-width: $tablet-width-min) {
    max-width: 80vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.centered-text {
  text-align: center;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
}

.mdc-slider {
  margin: 0 50px;
  @media (max-width: $tablet-width-min) {
    margin: 0 2em;
  }
}

.mdc-slider__value-indicator-container {
  width: max-content;

  .mdc-slider__value-indicator {
    height: fit-content;
  }
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin: 0 16px;

  p {
    margin: 0;
  }
}

.slider-left-end {
  width: 5px;
  height: 20px;
  margin-top: -8px;
  background-color: theme.$primary;
}

.slider-right-end {
  width: 5px;
  height: 20px;
  margin-top: -20px;
  background-color: theme.$secondary;
  margin-left: 100%;
}

.slider-result {
  @media (max-width: $tablet-width-min) {
    padding: 0 2em;
  }
}

.fullwidth {
  width: 100%;
}

.mdc-linear-progress {
  margin-bottom: 20px;
  height: 10px;
  @media (max-width: $tablet-width-min) {
    margin-bottom: 10px;
    height: 4px;
  }
}

.mdc-linear-progress__bar {
  background-image: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    violet
  );
}

.mdc-linear-progress__bar-inner {
  display: none;
}

trix-toolbar {
  .trix-button--fontawesome {
    line-height: 1.6em;
    text-indent: unset;
  }
}

.mdc-radio {
  @include radio.checked-stroke-color(#5721e5);
  @include radio.ink-color(#5721e5);
}

audio {
  margin: 0 15%;
  width: 70%;
  @media (max-width: $tablet-width-min) {
    margin: 2vw;
    width: 95%;
  }
}

.mdc-button__label {
  font-size: 18px;
}

.single-button {
  margin: 10px 0;
  text-align: center;
}

.goal-setting {
  .mdc-form-field {
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    max-width: 450px;
    min-height: 60px;
    margin: 5px 0;
    padding: 5px;
    width: 100%;

    &.selected {
      background-color: theme.$secondary;
    }
  }
}

#locator-location {
  margin-bottom: 12px;

  label {
    margin-right: 24px;
  }
}

#locator-distance {
  margin-bottom: 12px;
}
