#hivCalc,
#stiCalc {
  table {
    table-layout: fixed;
    margin: auto;

    button {
      &.clicked {
        background-color: #17a2b8;
      }

      img {
        max-width: 100%;
      }
    }
    .sexBtn {
      @media only screen and (max-width: 768px) {
        max-width: 45%;
      }
    }
    #partner-column {
      padding-bottom: 25px;
      @media only screen and (max-width: 768px) {
        padding-top: 15px;
      }
    }
  }

  span {
    margin: 0 auto;
    font-size: 1.5em;
    @media only screen and (max-width: 768px) {
      font-size: 1.75em;
    }
  }

  .hiv-red-border {
    border: 10px solid #e04b3a;
    border-radius: 1em;
  }

  .sti-purple-border {
    border: 10px solid #99509d;
    border-radius: 1em;
  }

  .hiv-red {
    color: #e04b3a;
    font-size: inherit;
  }

  .sti-purple {
    color: #99509d;
    font-size: inherit;
  }

  .section-title {
    font-size: 1.25em;
    font-weight: bold;
    @media only screen and (max-width: 768px) {
      font-size: 0.85em !important;
    }
  }

  #hiv_risk {
    font-size: 1.25em;
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
      display: block;
      text-align: center;
      font-size: 1.5em;
    }
  }

  #explanation_title {
    text-align: left;
    width: 100%;
    font-size: 1.25em;
    @media only screen and (max-width: 768px) {
      font-size: 1.5em;
    }
  }

  #riskBar {
    height: 3em;
    border-radius: 3px;
    display: flex;
    z-index: 1;
    border: 1px solid #0f2a65;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-bottom: 1.5em;
    }

    #arrow {
      opacity: 0;
      border-top: 0.25em solid white;
      border-bottom: 0.25em solid white;
      top: calc(50% - 0.25em);
      position: absolute;
      transition: 1s;

      &.left:after {
        content: '';
        border-top: 1em solid transparent;
        border-right: 1em solid white;
        border-bottom: 1em solid transparent;
        position: absolute;
        top: -1em;
        left: -1em;
      }
      &.right:after {
        content: '';
        border-top: 1em solid transparent;
        border-left: 1em solid white;
        border-bottom: 1em solid transparent;
        position: absolute;
        top: -1em;
        right: -1em;
      }
    }

    .sliders {
      width: 0.5em;
      height: 125%;
      position: absolute;
      left: 0;
      top: calc(-12.5%);
      transition: 1s;
      border-radius: 3px;
      opacity: 0;

      &#partnerSlider {
        background: black;
      }

      &#factorSlider {
        background: grey;
      }
    }

    .bars {
      height: 100%;
      z-index: -1;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: white;
      transition: 1s;

      &#regBar {
        font-size: 1.25em;
        @media only screen and (max-width: 768px) {
          font-size: 1.75em;
        }
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(to right, #4cd964, #ffcc00, #ff3b30);

        &.fullbar {
          font-size: 1em;
          width: 35%;
        }
      }

      &#xtrmBar {
        font-size: 0;
        width: 0;
        justify-content: center;
        background: linear-gradient(to right, #960800, #4b0400);

        &.fullbar {
          font-size: 1.25em;
          width: 65%;
        }
      }
    }
  }

  #key_title {
    display: block;
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 5px;
    @media only screen and (max-width: 768px) {
      font-size: 1.75em;
    }
  }

  #column_holder {
    display: flex;
    border: 1px solid #0f2a65;
    border-radius: 3px;
    justify-content: space-around;
    align-items: top;
    word-break: break-word;
    max-width: 75%;
    margin: auto;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }

    div {
      flex: 1;
      span {
        font-size: 1em;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0.75;
        font-size: 1em;
        @media only screen and (max-width: 768px) {
          font-size: 0.7em;
          margin-left: 5%;
          margin-right: 5%;
        }
        margin-left: 20%;
        margin-right: 20%;
        margin-bottom: 5px;
        margin-top: 5px;

        img {
          width: 20%;
          align-self: center;
          @media only screen and (max-width: 768px) {
            width: 30%;
          }
        }
      }
    }
  }
  .hiv-calc-border {
    padding: 5px;
    border: 5px solid #e04b3a;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
