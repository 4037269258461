$tablet-width-min: 600px;

.locator {
  margin: auto;
}

.resources {
  max-width: 100%;
  margin: auto;
  padding-left: 15%;
  padding-right: 15%;
  @media (max-width: $tablet-width-min) {
    padding: unset;
  }

  h2 {
    color: #c00001;
  }

  h3,
  h4 {
    margin-block-end: unset;
  }

  p {
    margin-block-start: 0.25em;
    margin-block-end: 0.5em;
  }

  .resources-contents {
    margin-left: 25%;
    margin-right: 25%;
    @media (max-width: $tablet-width-min) {
      margin: auto;
    }
    li {
      margin-bottom: 1%;
    }
  }

  .resources-section {
    margin-top: 5%;
  }

  .resources-subsection {
    margin-bottom: 5%;
  }
}

.iat-form {
  max-width: 100%;
}

.iat-radio-group {
  width: 100%;
  .mdc-form-field {
    label {
      margin-bottom: 8px;
      padding-left: 0;
    }
    @media (max-width: $tablet-width-min) {
      display: block;
    }
  }

  @media (max-width: $tablet-width-min) {
    padding-right: 1em;
  }
}
