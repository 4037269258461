$mobile-width-max: 599px;

.audio-transcript-container {
  .audio-transcript-trigger {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: $mobile-width-max) {
      .mdc-touch-target-wrapper {
        margin: auto 0.5rem;

        button {
          height: 4rem;
        }
      }
    }
  }

  .mdc-dialog__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    .mdc-dialog__title {
      font-weight: bold;
    }
  }

  .mdc-dialog .mdc-dialog__content {
    padding-top: 0px;
  }
}
